<template>
  <div class="col-lg-6 my-2 border_end">
    <div class="row justify-content-center">
      <div class="col-auto text-center">
        <div class="page_title">
          DESCRIPTION
        </div>
      </div>
      <div class="col-12">
        <div class="page_title_underline mt-3 mx-auto"></div>
      </div>
    </div>
    <div class="row mx-0 my-4 my-md-5 justify-content-center">
      <div class="col-md-10 p-0" v-html="description">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Project Description Section',
  props: ['description']
}
</script>

<style scoped>
  .page_title {
    font-size: 14px;
    color: var(--green-color);
    font-family: "quicksand_bold", Sans-serif;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 6.8px;
  }

  .page_title_underline {
    border-bottom: 2px solid var(--gold-color);
    width: 131px;
  }
  .border_end {
    border-right: none;
  }
  @media (min-width: 992px) {
    .page_title {
      font-size: 21px;
    }
    .page_title_underline {
      width: 200px;
    }
    .border_end {
      border-right: 1px solid rgb(204, 204, 204);
    }
  }
</style>
